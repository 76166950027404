import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const HeaderNosotros = ({ titulo, descripcion, color, classe }) => {
    return (
        <div >
            <Grid container className="container_headerNosotros">
                <Grid xs={12} sm={12} md={8} lg={6} className={classe}>
                    <Typography className='letras_headerNosotros'>
                        <Box fontWeight="fontWeightBold" className="titulo_headerNosotros">
                            {titulo}
                        </Box>
                        <Box fontWeight="fontWeightBold" className="subtitulo_headerNosotros">
                            {descripcion}
                        </Box>
                    </Typography>
                </Grid>
                <Grid xs={0} sm={0} md={4} lg={6} className="container_verde" style={{ backgroundColor: color }}></Grid>
            </Grid>
            <Grid xs={12} className="Espacio"></Grid>
        </div>
    )
}

export default HeaderNosotros;
