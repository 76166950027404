import React, { useState, useEffect } from 'react';
import imagen from "../assets/img/icono_saturno_nosotros.svg";
import { validateInputs, crear_form_data, formulario, getError, mostrarError, Wait, Enviados, Errores, getServiceSimple, transformar } from "../JS/General";
import { Grid, List, ListItem, ListItemText, Typography, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const FormularioContacto = () => {
    const [currency, setCurrency] = React.useState(null);
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [correo, setCorreo] = React.useState('');
    const [telefono, setTelefono] = React.useState('');
    const [comentario, setComentario] = React.useState('');
    const [errores, setErrores] = React.useState([]);
    const [wait, setWait] = useState(false);
    const [mensaje, setMensaje] = React.useState('');
    const [modalMensaje, setModalMensaje] = useState(false);
    const [errorEnviar, setErrorEnviar] = useState(false);
    const [servicios, setServicios] = useState([]);


    useEffect(() => {
        getData();
    }, []);

    const getData = async (search = '') => {
        setWait(true);
        let { success, data } = await getServiceSimple(1, search);
        if (success) {
            if (data.length != 0) {
                let s = await transformar(data, 'nombre', 'nombre');
                s.push({ value: 'Otro', label: 'Otro', });
                setServicios(s);
            }
        }
        setWait(false);
    }

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };

    const notificar = async () => {
        let data = {
            asunto: 'Información Portal Quiklii',
            nombre: `${nombre} ${apellido}`,
            correo,
            celular: telefono,
            mensaje: `${currency && currency} - ${comentario}`,
        }
        let errores = await validateInputs([
            { value: nombre, name: 'nombre', err: { empty: true } },
            { value: apellido, name: 'apellido', err: { empty: true } },
            // { value: currency ? currency : '', name: 'servicio', err: { empty: true } },
            { value: correo, name: 'correo', err: { empty: true, email: true } },
            { value: telefono, name: 'telefono', err: { empty: true } },
            { value: comentario, name: 'comentario', err: { empty: true } },
        ]);

        if (errores.length == 0) {
            setErrores([]);
            setWait(true);
            setModalMensaje(true);
            setErrorEnviar(false);
            data = await crear_form_data(data);
            formulario("notificacion/crear", data, "post",
                (error, estado, resp) => {
                    setWait(false);
                    if (estado === 200) {
                        setNombre('');
                        setApellido('');
                        setComentario('');
                        setCorreo('');
                        setTelefono('');
                        setCurrency(null);
                        setMensaje(resp.titulo);
                    } else {
                        setMensaje(resp.titulo ? resp.titulo : mostrarError(resp));
                        setErrorEnviar(true);
                    }
                }, false
            );
        } else {
            setErrores([]);
            setErrores(errores);
        }
    }


    return (
        <div>
            <Grid container justifyContent="center"  >
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                    <br />
                    <br />
                    <img src={imagen} className="imagen_DescipcionNosotros" />
                    <List>
                        <ListItem >
                            <ListItemText
                                primary={<span className='tituloLista'>Teléfono</span>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                        </Typography>
                                        {"018000180532"}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem >
                            <ListItemText
                                primary={<span className='tituloLista'>Locación</span>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                        </Typography>
                                        {"Bogotá, Colombia"}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6}>
                    <p className='titulo_contacto'>Contáctanos</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                onChange={({ target: { value } }) => setNombre(value)}
                                value={nombre}
                                label="Nombre/s"
                                variant="outlined"
                                error={getError('nombre', errores) && true}
                                helperText={getError('nombre', errores)}
                                fullWidth
                                disabled={wait}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                onChange={({ target: { value } }) => setApellido(value)}
                                value={apellido}
                                label="Nombre"
                                label="Apellido/s"
                                variant="outlined"
                                error={getError('apellido', errores) && true}
                                helperText={getError('apellido', errores)}
                                fullWidth
                                disabled={wait} />
                        </Grid>
                    </Grid>
                    <p className='titulo_contacto'>¿Cómo quieres ser contactado?*</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                type='email'
                                onChange={({ target: { value } }) => setCorreo(value)}
                                value={correo}
                                label="Correo electrónico"
                                variant="outlined"
                                error={getError('correo', errores) && true}
                                helperText={getError('correo', errores)}
                                fullWidth
                                disabled={wait}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                onChange={({ target: { value } }) => setTelefono(value)}
                                value={telefono}
                                label="Teléfono de contacto"
                                variant="outlined"
                                error={getError('telefono', errores) && true}
                                helperText={getError('telefono', errores)}
                                fullWidth
                                disabled={wait} />
                        </Grid>
                    </Grid>
                    <p className='titulo_contacto'>¿Te interesó algun servicio en especial?</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                disabled={wait}
                                id="outlined-select-currency"
                                select
                                label="Seleccionar Servicio"
                                value={currency}
                                onChange={handleChange}
                                error={getError('servicio', errores) && true}
                                helperText={getError('servicio', errores)}
                            >
                                {servicios.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <p className='titulo_contacto'>Comentarios adicionales *</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                onChange={({ target: { value } }) => setComentario(value)}
                                value={comentario}
                                label="Agrega un comentario"
                                variant="outlined"
                                multiline
                                error={getError('comentario', errores) && true}
                                helperText={getError('comentario', errores)}
                                rows={5}
                                fullWidth
                                disabled={wait}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Button disabled={wait} variant="contained" style={{ backgroundColor: '#004A73' }} onClick={() => notificar()}> Escríbenos </Button>
                </Grid>
            </Grid>
            <ModalCargando modal={modalMensaje} setModal={setModalMensaje} wait={wait} mensaje={mensaje} error={errorEnviar} />
        </div>
    )
}

function ModalCargando({ modal, setModal, wait, mensaje, error }) {
    return (
        <Dialog
            open={modal}
            onClose={() => setModal(false)}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle className='titulo_modal'>
                <h4>Contacto</h4>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: 'center' }}>
                    {wait && <Wait />}
                    {!error && !wait && <Enviados />}
                    {error && !wait && <Errores mensaje={mensaje} />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setModal(false)} >  Cerrar </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FormularioContacto;
