import react from 'react';
import { Grid, Typography, Box } from '@mui/material';
import mision from "../assets/img/foto_mision.png";

const Mision = () => {

    return (
        <Grid container justifyContent="center" spacing={2} className="container_mision">
            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className="container_mision_texto">
                <Typography >
                    <Box fontWeight="fontWeightLight" className="titulo">
                        NUESTRA MISIÓN
                    </Box>
                    <Box fontWeight="fontWeightBold" className="subtitulo">
                        Innovar e inspirar para transformar positivamente a la vida de las personas.
                    </Box>
                    <Box fontWeight="fontWeightLight" className="parrafo">
                        Ofrecer soluciones de contenido innovadoras en canales digitales para entretener, inspirar y conectar a las personas
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='container_imagen_mision'>
                <img src={mision} className='imagen_mision' />
            </Grid>
        </Grid>
    )
}

export default Mision;
