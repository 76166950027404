import * as React from 'react';
import {
  Link
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import logo_azul from "../assets/img/logo_azul.svg";
import facebook from "../assets/img/facebook.png";
import instagram from "../assets/img/instagram.png";
import Linkedin from "../assets/img/Linkedin.png";
import lugar from "../assets/img/lugar.png";
import telefono from "../assets/img/telefono.png";
import correo from "../assets/img/correo.png";

export default function Footer() {
  let currentUrl = window.location.pathname.split("/")[1];
  const isCheck = (url) => (url == currentUrl ? { backgroundColor: '#004A73', color: 'white' } : {})
  return (
    <Grid container alignContent='center' alignItems='center' justifyContent="center" spacing={2} className='footer'>
      <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><img src={logo_azul} className='logo_footer' /></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ><p className='descripcion_footer'>Quicklii transforma tu mundo a través de la tecnología</p> </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><img className='icon_footer' src={facebook} width={19} height={19}/><img className='icon_footer' src={instagram}  width={19} height={19}/><img className='icon_footer' src={Linkedin}  width={19} height={19}/></Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
        <p className='navegacion-del-sitio'>Navegación del Sitio</p>
        <p><Link className='nosotros-servicios-c-link' style={isCheck("inicio")} to="/inicio">Inicio</Link></p>
        <p><Link className='nosotros-servicios-c-link' style={isCheck("servicios")} to="/servicios">Servicios</Link></p>
        <p><Link className='nosotros-servicios-c-link' style={isCheck("nosotros")} to="/nosotros">Nosotros</Link></p>
        <p><Link className='nosotros-servicios-c-link' style={isCheck("contacto")} to="/contacto">Contacto</Link></p>
      </Grid>
      <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
        <Grid container>
          <p className='navegacion-del-sitio'>Contacto</p>
          <span className='nosotros-servicios-c' > <img className='icon_footer_contacto' src={telefono} width={19} height={19} />018000180532</span>
          <span className='nosotros-servicios-c'> <img className='icon_footer_contacto' src={correo} width={19} height={19} />customer.support@quicklii.co</span>
          <span className='nosotros-servicios-c'> <img className='icon_footer_contacto' src={lugar} width={19} height={19} />Carrera 7 # 116-50</span>
          <Button component={Link} to="/contacto" variant="contained" className='btn_footer'> Escríbenos </Button>
        </Grid>
      </Grid>
      <p className='footer_copy'>Diseñado por Molushca Studio</p>
    </Grid>
  );
}
