import React from 'react';
import imagen from "../assets/img/icono_saturno_nosotros.svg";
import { Grid, Typography, Box } from '@mui/material';

const DescriptionNosotros = () => {
    return (
        <div>
            <Grid container>
                <Grid xs={12} className="ContainerImagen_DescipcionNosotros">
                    <img src={imagen} className="imagen_DescipcionNosotros"></img>
                </Grid>
            </Grid>
            <Grid container className="container_descripcion">
                <Grid xs={12} sm={8} md={6} lg={6}>
                    <Typography>
                        <Box fontWeight="fontWeightLight" className="titulo_descripcionNosotros">
                            TEMA 02
                        </Box>
                        <Box fontWeight="fontWeightBold" className="subtitulo__descripcionNosotros">
                            Lideres en soluciones enfocadas en la rentabilidad y conexión de las personas.
                        </Box>
                        <Box fontWeight="fontWeightBold" className="parrafo_descripcionNosotros">
                            Ser líderes en el mercado de proveedores de los Operadores Móviles, a través de nuestras soluciones innovadoras que ofrecen una alta rentabilidad y excelente experiencia de los usuarios.
                        </Box>
                    </Typography>
                </Grid>
                <Grid xs={0} sm={4} md={6} lg={6} className="container_verde"></Grid>
            </Grid>
        </div>
    )
}
export default DescriptionNosotros;
