
import Menu from "../componentes/Menu";
import Header from "../componentes/Header";
import Mision from "../componentes/Mision";
import Vision from "../componentes/Vision";
import Nosotros from "../componentes/Nosotros";
import Impacto from "../componentes/Impacto";
import Linea from "../componentes/Linea";
import Servicios from "../componentes/Servicios";
import Footer from "../componentes/Footer";
import logo from "../assets/img/logo.svg";


function Inicio() {
  return (
    <>
      <Menu logo={logo} backgroundColor={'rgba(0,0,0,0.8)'} color={'white'} />
      <Header />
      {/* <div className='separador'></div> */}
      <Mision />
      <div className='separador'></div>
      <Vision />
      <div className='separador'></div>
      <Nosotros />
      <Impacto />
      <Linea />
      <Servicios />
      <Footer />
    </>
  );
}

export default Inicio;
