import React, { Component, useState } from 'react';
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import Inicio from "./templates/Inicio";
import Nosotros from "./templates/Nosotros";
import Contacto from "./templates/Contacto";
import Servicios from "./templates/Servicios";
import './App.css';



const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Inicio /> },
    { path: "/inicio", element: <Inicio /> },
    { path: "/nosotros", element: <Nosotros /> },
    { path: "/contacto", element: <Contacto /> },
    { path: "/servicios", element: <Servicios /> },
    // ...
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
