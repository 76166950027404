
import Menu from "../componentes/Menu";
import HeaderNosotros from "../componentes/HeaderNosotros";
import FormularioContacto from "../componentes/FormularioContacto";
import Footer from "../componentes/Footer";
import logo from "../assets/img/logo.svg";


function Inicio() {
  return (
    <>
      <Menu logo={logo} backgroundColor={'#00A58E'} color={'white'} />
      <HeaderNosotros classe='containerTitulo_headerContacto' titulo='ESCRÍBENOS' descripcion='Quieres conocer más sobre nosotros o tienes alguna duda o pregunta?, Estameros gustosos de contestarte.' color='#00A58E' />
      <FormularioContacto />
      <Footer />
    </>
  );
}

export default Inicio;
