import react from 'react';
import { Grid, Typography, Box } from '@mui/material';
import vision from '../assets/img/foto_vision.png'
const Vision = ({ titulo = 'NUESTRA VISIÓN', subtitulo = 'Lideres en soluciones enfocadas en la rentabilidad y conexión de las personas.', parrafo = 'Ser líderes en el mercado de proveedores de los Operadores Móviles, a través de nuestras soluciones innovadoras que ofrecen una alta rentabilidad y excelente experiencia de los usuarios.' }) => {

    return (
        <Grid container justifyContent="center" spacing={2} className="container_vision">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='container_imagen_vision'>
                <img src={vision} className='imagen_vision' />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <div className="container_vision_texto">
                    <Typography >
                        <Box fontWeight="fontWeightLight" className="titulo">
                            {titulo}
                        </Box>
                        <Box fontWeight="fontWeightBold" className="subtitulo">
                            {subtitulo}
                        </Box>
                        <Box fontWeight="fontWeightLight" className="parrafo">
                            {parrafo}
                        </Box>
                    </Typography>
                </div>
            </Grid>
        </Grid>

    )
}

export default Vision;
