import React from "react";
import imagen from "../assets/img/logo_blanco.svg";
import { Grid } from '@mui/material';

const Nosotros = () => {
    return (
        <div className='imagenNosotrosInicio'>
            <div className='containerNosotrosInicio'>
                <img className='imgTituloNosotrosInicio' src={imagen} />
                <p className='parrafoNosotrosInicio'>Las comunicaciones y la tecnología son nuestro enfoque.</p>
            </div>
        </div>
    )
}

export default Nosotros
