
import Menu from "../componentes/Menu";
import HeaderNosotros from "../componentes/HeaderNosotros";
import ContainerServicios from "../componentes/ContainerServicios";
import Footer from "../componentes/Footer";
import logo from "../assets/img/logo.svg";


function Servicios() {
  return (
    <>
      <Menu logo={logo} backgroundColor={'#006F98'} color={'white'} />
      <HeaderNosotros classe='containerTitulo_headerServicios' titulo='NUESTROS SERVICIOS' descripcion='Generamos una variedad de temas que nos permiten ofrecerle gran variedad a nuestros usuarios.' color='#006F98' />
      <ContainerServicios />
      <Footer />
    </>
  );
}

export default Servicios;
