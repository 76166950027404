import React from "react";
import imagen from "../assets/img/icono_saturno_blue.svg";
import { Grid, Typography, Box } from '@mui/material';

const linea = () => {
    return (
        <>
        <div className="linea_verde" style={{textAlign: 'center', display: 'flex', alignItems: ' center', justifyContent: 'center'}}>
            <img src={imagen} className="icono_linea"></img>
        </div>
        <Grid xs={12} style={{height:'100px'}}></Grid>
        </>
    )
}
export default linea