import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import loaderBlack from "../assets/img/loaderBlack.gif";
import servicio_mes from "../assets/img/servicio_mes.png";
import telefono from "../assets/img/telefono.png";
import correo from "../assets/img/correo.png";
import { API, getServiceSimple, TransformText } from '../JS/General'

const ContainerServicios = () => {
  const [modalTermino, setModalTerminos] = useState(false);
  const [servicio, setServicio] = useState({});
  const [servicios, setServicios] = useState([]);
  const [wait, setWait] = useState(true);
  const [errorContent, setErrorContent] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (search = '') => {
    setWait(true);
    let { success, data } = await getServiceSimple(1, search);

    if (success) {
      if (data.length != 0) setServicios(data);
      // else Toast.fire({ icon: 'info', title: "No se encontraron servicios!" });
    }
    else setErrorContent(true);
    setWait(false);
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center" justify="center" alignItems="center" className='container_servicios_cards'>
        {
          wait && <img src={loaderBlack} />
        }
        {
          servicios.map(({ nombre, valors, valore }) => (
            <Grid item xs={8} sm={6} md={4} lg={3} xl={3} >
              <div className="container_servicios"
                onClick={() => {
                  setModalTerminos(true);
                  setServicio({ nombre, terminos: valore });
                }}
              >
                <div className='containerIconoServicio'>
                  <img
                    className="iconos_servicios"
                    alt="Logo"
                    src={valors}
                  />
                </div>
                <Typography className="titulo_card_servicio">
                  <Box fontWeight="fontWeightBold">
                    {nombre}
                  </Box>
                </Typography>
              </div>
            </Grid>
          ))
        }
      </Grid>
      <div className='separador'></div>
      <div onClick={()=>{
        getData();
      }} className='container_servicio_mes' >
        <p className='titulo_servicio_mes'>SERVICIO DEL MES</p>
        <img src={servicio_mes} className='imagen_servicio_mes' onClick={() => window.open('http://quicklii.co/lateleletal/31?uId=0')} />
      </div>
      <ModalTerminos modal={modalTermino} setModal={setModalTerminos} servicio={servicio} />
    </>

  )
}


function ModalTerminos({ modal, setModal, servicio }) {
  return (
    <Dialog
      open={modal}
      onClose={() => setModal(false)}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle className='titulo_modal'>
        <h4>{servicio.nombre}</h4>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p className='nosotros-servicios-c' > <img className='icon_footer_contacto' src={telefono} width={19} height={19} />018000180532</p>
          <p className='nosotros-servicios-c'> <img className='icon_footer_contacto' src={correo} width={19} height={19} />customer.support@quicklii.co</p>
          {servicio && <TransformText text={servicio.terminos} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModal(false)} >  Cerrar </Button>
      </DialogActions>
    </Dialog>
  );
}



export default ContainerServicios;
