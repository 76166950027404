
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import loading from "../assets/img/loading.gif";
import rating from "../assets/img/rating.png";
import notification from "../assets/img/notification.png";

export const API = "http://3.14.188.42";
export const APIALT = "https://5z55zb4skb.execute-api.us-east-2.amazonaws.com/default/quickliiServicesProxy";

export const consulta = (url, data = null, method = null, callback, authorization = true) => {
  let parametros = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if (method) parametros.method = method;
  if (authorization) parametros.headers.Authorization = `JWT ${localStorage.getItem('token')}`;
  if (data) parametros.body = JSON.stringify(data);

  fetch(APIALT, parametros).then(async response => {
    const estado = response.status;
    const resp = await response.json();
    callback(null, estado, resp)
  }).catch(error => callback(error));
}

export const mostrarError = (errores) => {
  for (var k in errores) {
    let r = errores[k];
    let mensaje = '';
    r.forEach((element) => {
      mensaje = `${mensaje} ${element}`;
    });
    return `${k} : ${mensaje}`;
  }
};

export const formulario = (url, data, method, callback, authorization = true) => {
  let headers = {};
  if (authorization) headers.Authorization = `JWT ${localStorage.getItem('token')}`;

  fetch(`${API}/api/v1.0/${url}`, {
    method,
    cache: 'no-cache',
    mode: 'cors',
    body: data,
    headers,
  }).then(async response => {
    const estado = response.status;
    const resp = await response.json();
    callback(null, estado, resp)
  }).catch(error => callback(error));
}

export const crear_form_data = (data) => {
  return new Promise((resolve) => {
    let key = Object.keys(data);
    let formData = new FormData();
    key.forEach((key) => formData.append(key, data[key]));
    resolve(formData);
  });
};

export const validateInputs = (campos = []) => {
  return new Promise((resolve) => {
    let errores = [];
    campos.map(({ value, name, err }) => {
      if (err.empty && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio.` });
      if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero.` });
      if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Introduzca una dirección de correo electrónico válida.` });
    });

    resolve(errores);
  });
}

export const getError = (llave, errores) => {
  let existe = errores.find(e => e.llave === llave);
  return existe ? existe.mensaje : '';
}

export const getServiceSimple = (pk, search = '') => {
  return new Promise((resolve) => {
    consulta(`generica/${pk}/servicios?search=${search}`, null, null, (error, estado, resp) => {
      if (estado === 200) resolve({ success: true, data: resp });
      else resolve({ success: false });
    }, false);
  });
};

export const generarNumero = (max, min) => {
  return Math.random() * (max - min) + min;
}

export function TransformText({ text }) {
  let list = text.search("!");
  let listF = text.search("¡");
  if (list != -1 && listF != -1) {
    let resp = text.split('¡');
    return resp.map((e, i) => {
      let p = e.split('!');
      if (p.length == 1) return '';
      return (
        <List key={i}>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={``}
              secondary={
                <React.Fragment>
                  <p > <b>{p[0]}</b> </p>
                  <p > {p[1]} </p>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      );
    })
  } else return <p  >{text}</p>
}


export const transformar = (data, id = 'id', nombre = 'nombre') => {
  return data.map((e) => ({
    value: e[id],
    label: e[nombre]
  }));
};

export function Wait({ mensaje = 'Cargando...' }) {
  return (
    <div style={{ padding: "5% 0 5% 0", margin: 0, width: "100%", textAlign: 'center' }}>
      <div>
        <img src={loading} height="150" alt="Sin datos" />
        <p>{mensaje}</p>
      </div>
    </div>
  );
}

export function Enviados({ mensaje = 'Datos Enviados.!' }) {
  return (
    <div style={{ padding: "5% 0 5% 0", margin: 0, width: "100%", textAlign: 'center' }}>
      <div>
        <img src={rating} height="150" alt="Sin datos" />
        <p>{mensaje}</p>
      </div>
    </div>
  );
}

export function Errores({ mensaje = '' }) {
  return (
    <div style={{ padding: "5% 0 5% 0", margin: 0, width: "100%", textAlign: 'center' }}>
      <div>
        <img src={notification} height="150" alt="Sin datos" />
        <p>{mensaje}</p>
      </div>
    </div>
  );
}


export const validarCorreo = (valor) => {
  let valido = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    valor
  );
  console.log(valido);
  return valido;
};
