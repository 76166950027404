
import Menu from "../componentes/Menu";
import Mision from "../componentes/Mision";
import Vision from "../componentes/Vision";
import Footer from "../componentes/Footer";
import HeaderNosotros from "../componentes/HeaderNosotros";
import CitarAutorNosotros from "../componentes/CitarAutorNosotros";
import DescriptionNosotros from "../componentes/DescripcionNosotros";
import logo from "../assets/img/logo.svg";

function Nosotros() {
  return (
    <>
      <Menu logo={logo} backgroundColor={'#C9DB8D'} color={'white'} />
      <HeaderNosotros classe='containerTitulo_headerNosotros' titulo='EL CORAZÓN DE NUESTRA EMPRESA' descripcion='Somos un equipo multidiciplinario que trabajo en sinergia con cada uno de nuestras áreas de experiencia.' color='#C9DB8D' />
      <Vision titulo='¿ QUIÉNES SOMOS' subtitulo='Quicklii' parrafo='Somos una empresa de tecnología fundada en Colombia con foco en dispositivos móviles. Desarrollamos servicios de contenido premium para web, aplicaciones móviles, redes sociales. Somos partner de Google para el canal de comunicación RCS. Nuestro equipo cuenta con 15 años de experiencia en el negocio con Operadores Móviles en Latinoamérica.' />
      <div className='separador'></div>
      <CitarAutorNosotros />
      <Mision />
      <div className='separador'></div>
      <DescriptionNosotros />
      <Footer />
    </>
  );
}

export default Nosotros;
