import * as React from "react";
import header from "../assets/img/header.png";
import Grid from "@mui/material/Grid";
import cohete from "../assets/img/cohete.png";
import documento from "../assets/img/html.png";
import network from "../assets/img/network.png";
import proteccion from "../assets/img/proteccion.png";

export default function Header() {
  return (
    <>
      <div className="imagenHeader">
        <div className="containerHeader">
          <p className="tituloHeader">MULTIPROPÓSITO</p>
          <p className="SubTituloHeader">
            SOMOS UN PUENTE ENTRE LOS CREADORES Y SUS USUARIOS
          </p>
          <p className="parrafoHeader">
            Hacemos que los creadores de contenidos puedan llegar a sus usuarios
            y monetizamos sus esfuerzos.{" "}
          </p>
        </div>
      </div>

      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ top: -30, position: "relative", marginBottom: 70 }}
      >
        <Grid className="card_header" item xs={12} sm={6} md={4} lg={2} xl={2}>
          <Grid container style={{ padding: "15px 15px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="iconos_header"
            >
              <img src={cohete} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span className="titulo_card_header">Quicklii SVA</span>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="descripcion_card_header"
            >
              Unidad de negocio para el desarrollo e integración de Servicios de
              Valor Agregado con Operadores Móviles.
            </Grid>
          </Grid>
        </Grid>
        <Grid className="card_header" item xs={12} sm={6} md={4} lg={2} xl={2}>
          <Grid container style={{ padding: "15px 15px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="iconos_header"
            >
              <img src={documento} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span className="titulo_card_header">Quicklii Midia</span>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="descripcion_card_header"
            >
              Unidad de negocio proveedora de nuevos canales digitales para
              cualquier sector de la economía. Partner Actual: Google / Agente
              habilitado para el canal RCS Business Messaging.
            </Grid>
          </Grid>
        </Grid>
        <Grid className="card_header" item xs={12} sm={6} md={4} lg={2} xl={2}>
          <Grid container style={{ padding: "15px 15px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="iconos_header"
            >
              <img src={network} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span className="titulo_card_header">Quicklii Conexión</span>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="descripcion_card_header"
            >
              Unidad de negocio para la interconexión de integradores
              tecnológicos en los Operadores móviles de Latam.
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="card_header"
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          xl={2}
          onClick={() => {
            window.open(
              "https://quicklii-docs.s3.us-east-2.amazonaws.com/PoliticaDeTratamientoDeDatosQuicklii.pdf"
            );
          }}
        >
          <Grid container style={{ padding: "15px 15px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="iconos_header"
            >
              <img src={proteccion} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span className="titulo_card_header">
                Política de tratamiento de datos
              </span>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="descripcion_card_header"
            >
              Click aquí para consultar nuestra política de datos.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
