import React from 'react';
import imagen from "../assets/img/imagen_autor.png"
import { Grid, Typography, Box } from '@mui/material';

const CitarAutorNosotros = () => {
    return (
        <div>
            <Grid container className="container_CitarAutor">
                <Grid xs={12} sm={12} md={10} lg={8}>
                    <Typography>
                        <Box fontWeight="fontWeightBold" className="titulo_citar">
                            "Transformamos las transacciones digitales en entretenimiento a través de contenidos que mejoran la calidad de vida de las personas."
                        </Box>
                        <Box fontWeight="fontWeightLight" className="subtitulo__citar">
                            Quicklii
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
            <Grid xs={12} className="Espacio"></Grid>
        </div>
    )
}

export default CitarAutorNosotros;
