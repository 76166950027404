import * as React from 'react';
import {
  Link
} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuM from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Hidden from '@mui/material/Hidden';

const isCheck = (url, backgroundColor = 'white', color = '#6D7278') => {
  let currentUrl = window.location.pathname.split("/")[1];
  return (url == currentUrl ? { backgroundColor, color } : {})
}

function MenuDesktop() {
  return (
    <>
      <Link className='item_menu' style={isCheck("inicio")} to="/inicio">Inicio</Link>
      <Link className='item_menu' style={isCheck("servicios")} to="/servicios">Servicios</Link>
      <Link className='item_menu' style={isCheck("nosotros")} to="/nosotros">Nosotros</Link>
      <Link className='item_menu' style={isCheck("contacto")} to="/contacto">Contacto</Link>
    </>
  );
}


function MenuMovil() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='menu_app'
      >
        Menu
      </Button>
      <MenuM
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={isCheck("inicio", 'grey', 'white')} component={Link} to="/inicio" onClick={handleClose}>Inicio</MenuItem>
        <MenuItem style={isCheck("servicios", 'grey', 'white')} component={Link} to="/servicios" onClick={handleClose}>Servicios</MenuItem>
        <MenuItem style={isCheck("nosotros", 'grey', 'white')} component={Link} to="/nosotros" onClick={handleClose}>Nosotros</MenuItem>
        <MenuItem style={isCheck("contacto", 'grey', 'white')} component={Link} to="/contacto" onClick={handleClose}>Contacto</MenuItem>
      </MenuM>
    </div>
  );
}

export default function Menu({ backgroundColor, color, logo }) {
  return (
    <AppBar position="fixed" className='menu' style={{ backgroundColor, color }} elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src={logo} className='logo' />
        </Typography>
        <Hidden mdDown><MenuDesktop /></Hidden>
        <Hidden mdUp><MenuMovil /></Hidden>
      </Toolbar>
    </AppBar>
  )
}
