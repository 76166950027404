import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const Impacto = () => {
    return (
        <div className='containerImpacto'>
            <Grid container style={{ alignItems: ' center', justifyContent: 'center' }}>
                <Grid item md={12}>
                    <Typography className="textImpacto">
                        <Box fontWeight="fontWeightLight" className="titulo_impacto">
                            IMPACTO
                        </Box>
                    </Typography>
                    <Typography className="textImpacto">
                        <Box fontWeight="fontWeightBold" className="subtitulo_impacto">
                            Nuestro impacto habla <br /> por si solo
                        </Box>
                    </Typography>
                </Grid>
                <Grid container style={{ alignItems: ' center', justifyContent: 'center' }}>
                    <Grid item md={3}>
                        <Typography >
                            <Box fontWeight="fontWeightBold" className="numero">
                                +20
                            </Box>
                            <Box fontWeight="fontWeightBold" className="subtitulo_numero">
                                Servicios Móviles
                            </Box>
                        </Typography>
                    </Grid>
                    {/* <span className="impactoLineas"/> */}
                    <Grid item md={3} >
                        <Typography >
                            <Box fontWeight="fontWeightBold" className="numero">
                                +350k
                            </Box>
                            <Box fontWeight="fontWeightBold" className="subtitulo_numero">
                                Usuarios Suscritos
                            </Box>
                        </Typography>
                    </Grid>
                    {/* <span className="impactoLineas"/> */}
                    <Grid item md={3}>
                        <Typography>
                            <Box fontWeight="fontWeightBold" className="numero">
                                +1MM
                            </Box>
                            <Box fontWeight="fontWeightBold" className="subtitulo_numero">
                                Transacciones <br/> mensuales
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Impacto